<template>
  <div class="px-40 border border-EEEEEE py-35 purseBox">
    <!-- 标签 -->
    <div class="flex align-center justify-between pb-20 border-bottom border-F4F4F4">
      <div class="font-20 text-333333">消费清单</div>
      <div class="font-12 text-666666 flex align-center">
        目前所在：<el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/myIndex' }">个人中心</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/Mypromotion' }">我的推广</el-breadcrumb-item>
          <el-breadcrumb-item>消费清单</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!-- 内容 -->
    <div>
      <div class="font-weight-bolder pt-20">
        用户昵称：{{ info.remark ? info.remark : info.username }}
      </div>
      <div class="py-20 border-bottom border-EEEEEE flex align-center justify-between">
        <div class="flex">
          <!-- <img
            :src="info.avatar"
            width="3.3125rem"
            height="3.3125rem"
            class="circle mr-20"
            alt=""
          /> -->
          <el-image
            class="circle mr-20"
            style="width: 3.3125rem; height: 3.3125rem"
            :src="info.avatar"
            fit="cover"
          ></el-image>
          <div class="font-14">
            <div class="mb-10">累计订单：{{ info.order_nums }}笔</div>
            <div class="mb-12">消费：{{ info.order_money }}元</div>
            <div class="text-B5B5B5" v-if="info.end_time">时间：{{ info.end_time }}</div>
          </div>
        </div>
        <div>
          <div class="mb-35 font-14">获得佣金：{{ info.sale_money }}元</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import req from "../../../utils/req";
export default {
  data() {
    return {
      subordinateid: 0,
      info: "",
    };
  },
  watch: {
    // 被侦听的变量count
    $route: {
      handler() {
        let subordinateid = this.$route.query.subordinateid;
        if (subordinateid) {
          this.subordinateid = subordinateid;
          this.getdata();
        }
      },
      deep: true,
    },
  },
  mounted() {
    let subordinateid = this.$route.query.subordinateid;
    if (subordinateid) {
      this.subordinateid = subordinateid;
      this.getdata();
    }
  },
  methods: {
    // 获取数据
    getdata() {
      let that = this;
      req
        .post("center/saleLog", {
          id: that.subordinateid,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          that.info = res.data;
          console.log("that.info :>> ", that.info);
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.purseBox {
  height: 100%;
  box-sizing: border-box;
}
</style>
